import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import plusSignImage from './images/plus-sign.png';
import minusSignImage from './images/minus-sign.png';
import * as accordionData from './accordionData.js';
import css from './Accordion.module.css';

const AccordionIT = props => {

  const { rootClassName, className, urlQueryParams, location } = props;

  const categoryMap = {
    information_technology: 'itData',
    recruitment_and_hr: 'hrData',
    accountancy_banking_and_finance: 'financeData',
  };

  const pathnameMap = {
    'it-manager-informationtechnology': 'itManagerData',
    'marketing-manager-marketingandcommunications': 'marketingManagerData',
    'compliance-manager-consultingandstrategy': 'complianceManagerData',
    'data-scientist-informationtechnology': 'dataScientistData',
    'facilities-manager-administrationandofficesupport': 'facilitiesManagerData',
    'customer-service-representative-callcenterandcustomerservice': 'customerServiceRepresentativeData',
    'accounting-clerk-accounting': 'accountingClerkData',
    'staff-accountant-accounting': 'staffAccountantData',
    'it-director-informationtechnology': 'itDirectorData',
    'senior-accountant-accounting': 'seniorAccountantData',
    'accounts-manager-accounting': 'accountsManagerData',
    'chief-financial-officer-bankingandfinancialservices': 'chiefFinancialOfficerData',
    'it-support-specialist-informationtechnology': 'itSupportSpecialistData',
    'public-relations-manager-marketingandcommunications': 'prManagerData',
    'registered-nurse-healthcareandmedical': 'registeredNurseData',
    'hr-manager-humanresourcesandrecruitment': 'hrManagerData',
    'sales-manager-sales': 'salesManagerData',
    'operations-manager-executive': 'operationsManagerData',
    'plant-manager-manufacturingtransportandlogistics': 'plantManagerData',
    'hr-assistant-humanresourcesandrecruitment': 'hrAssistantData',
    'paralegal-legal': 'paralegalManagerData',
    'engineering-manager-engineering': 'engineeringManagerData',
  };

  const categoryKey = categoryMap[urlQueryParams?.pub_category];
  const pathnameKey = Object.keys(pathnameMap).find(key => location?.pathname?.includes(key));
  const dataKey = categoryKey || pathnameMap[pathnameKey];
  const data = dataKey ? accordionData[dataKey] : null;

  if (!data) return null;

  const [accordionIndex, setAccordionIndex] = useState(0);

  const toggleAccordion = index => {
    setAccordionIndex(index === accordionIndex ? -1 : index);
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>{data?.mainTitle}</h2>
      <div className={css.accordion}>
        {data?.accordionData?.map((item, i) => (
          <div
            key={i}
            className={classNames(css.accordionItem, {
              [css.accordionItemActive]: accordionIndex === i,
            })}
          >
            <h3 className={css.accordionTitle} onClick={() => toggleAccordion(i)}>
              {item.title}
              <img src={accordionIndex === i ? minusSignImage : plusSignImage} alt="" />
            </h3>
            {accordionIndex === i && (
              <div className={css.accordionDescription}>
                {item.description.map((p, idx) => (
                  <p key={idx}>{p}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

AccordionIT.defaultProps = {
  rootClassName: null,
  className: null,
};

AccordionIT.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  urlQueryParams: PropTypes.object,
  location: PropTypes.object,
};

export default AccordionIT;